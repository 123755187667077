import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	margin-top: 50px;
	z-index: 2;
	width: 100%;
	height: 80px;
	background: #2e3e4e;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
`
const Svg = styled.svg`
	outline: none;
	height: 50px;
	width: 50px;
	fill: #f1f1f1;
	margin: 15px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
        fill: #B2D4F5;
	};
	&:focus {
        fill: #B2D4F5;
    };
`;
class Footer extends Component {
	render() {
		return(
			<Wrapper>
				<Svg tabIndex={this.props.modal ? null : 1} onKeyDown={(e) => e.key === "Enter" && window.open('https://github.com/jansedav')} onClick={() => window.open('https://github.com/jansedav')} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.18 69.69"><path d="M965.27,554c1.21-.21,2.42-.37,3.59-.64a16.69,16.69,0,0,0,7.71-3.73,13.48,13.48,0,0,0,4.11-7.27,22.23,22.23,0,0,0,.43-8.75,13.5,13.5,0,0,0-3-6.39,1,1,0,0,1-.22-1,13.08,13.08,0,0,0-.43-8.49.89.89,0,0,0-.7-.42,14.14,14.14,0,0,0-3.4.68c-1.81.77-3.52,1.8-5.3,2.68a1.7,1.7,0,0,1-1.08.17,34.69,34.69,0,0,0-16.67,0,1.7,1.7,0,0,1-1.16-.21,24.71,24.71,0,0,0-6.27-3c-2.67-.74-3-.54-3.63,2.12a12.26,12.26,0,0,0,.2,6.66,1,1,0,0,1-.2.82,13.79,13.79,0,0,0-3.31,9.69,21.78,21.78,0,0,0,1.52,8.14,13.57,13.57,0,0,0,9,7.85c1.54.46,3.15.7,4.72,1l.84.19a8.11,8.11,0,0,0-2.15,4.42c0,.18-.26.39-.45.46-3.91,1.58-7.55.79-9.8-2.91a6.61,6.61,0,0,0-6.29-3.82c-.29,0-.79.26-.82.46a1.39,1.39,0,0,0,.41.93,3.23,3.23,0,0,0,.86.69,9.59,9.59,0,0,1,3.5,4.22,22.87,22.87,0,0,0,1.11,2.28c1.9,3.06,4.83,4,8.24,3.9,1,0,2-.2,3.12-.32,0,1.05,0,2.1,0,3.15,0,.82,0,1.64,0,2.46,0,1.83-1.1,2.61-2.82,2a35.83,35.83,0,0,1-13.59-59,34,34,0,0,1,25.34-10.52,34.52,34.52,0,0,1,26.15,11.58,33.94,33.94,0,0,1,9,18.93,35.85,35.85,0,0,1-23.69,39,1.86,1.86,0,0,1-2.71-1.92c0-3.07,0-6.14,0-9.21a10.23,10.23,0,0,0-1-5.08C966.14,555.22,965.7,554.66,965.27,554Z" transform="translate(-923.07 -502.54)"/></Svg>
				<Svg tabIndex={this.props.modal ? null : 1} onKeyDown={(e) => e.key === "Enter" && window.open('https://www.linkedin.com/in/david-jansen-cs/')} onClick={() => window.open('https://www.linkedin.com/in/david-jansen-cs/')}id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.02 81"><path d="M959.3,579.55a40.5,40.5,0,1,1,40.57-40.39A40.53,40.53,0,0,1,959.3,579.55Zm23.78-19.79c0-.28.06-.52.06-.75V542.84a19.29,19.29,0,0,0-1-6.56c-2.66-7.44-11.25-8.11-15.5-5.55a28.72,28.72,0,0,0-3.65,3.06v-3.63h-9.84v29.58H963V558.6q0-8,0-16a5.45,5.45,0,0,1,1.57-3.83c2.27-2.42,7.24-2.22,8.28,1.79a14.73,14.73,0,0,1,.46,3.56c.05,4.88,0,9.75,0,14.63v1Zm-45.33-29.6v29.6h9.81v-29.6ZM942.3,526c2.48,0,4.42-1,5.24-2.53a5.2,5.2,0,0,0-1.61-6.86c-2.21-1.43-6.06-.92-7.61,1a5.11,5.11,0,0,0,4,8.35Z" transform="translate(-918.86 -498.55)"/></Svg>
				<Svg tabIndex={this.props.modal ? null : 1} onKeyDown={(e) => e.key === "Enter" && window.open('https://www.facebook.com/people/David-Jansen/100008060264970')} onClick={() => window.open('https://www.facebook.com/people/David-Jansen/100008060264970')} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.99 78.62"><path d="M965.59,578.84V548h9.49l1.42-11.1H965.72c0-3-.15-6,0-8.89.15-2.2,1.75-3.36,4.19-3.44,1.93-.07,3.88,0,5.81-.05h1v-9.92c-4.29-.24-8.58-1-12.82.13-5.18,1.33-8.24,4.77-9.32,10a26.55,26.55,0,0,0-.41,4.72c-.07,2.46,0,4.93,0,7.48h-9.52V548h9.47v30.64a39.44,39.44,0,0,1-32.51-33.49,39.5,39.5,0,1,1,43.94,33.71Z" transform="translate(-921.27 -500.23)"/></Svg>
			</Wrapper>
		);
	}
}

export default(Footer);