import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Link = styled.div`
    outline: none;  
    cursor: pointer;
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    margin: 10px;
    color: #f1f1f1;
    transition: all ease-in-out 0.3s;
    &:hover {
        color: #B2D4F5;
    };
    &:focus {
        color: #B2D4F5;
    };
    @media (max-width: 1000px) {
        font-size: 24px;
    };
    @media (max-width: 800px) {
        font-size: 20px;
    };
`

class HeaderLinks extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    };


    handleLinks(link){
        var elmnt = document.getElementById(link);
        elmnt.scrollIntoView();
    }
    
    render() {
        return(
            <Link tabIndex={this.props.modal ? null : 1} onKeyDown={(e) => {e.key === "Enter" && this.props.setActive(this.props.name); this.handleLinks(this.props.name)}} onClick={() => {this.props.setActive(this.props.name); this.handleLinks(this.props.name)}} style={{color: this.props.active === this.props.name ? '#B2D4F5' : null}}>
                {this.props.name}
            </Link>
        );
    }
}

export default(HeaderLinks);