import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

const Cactus = styled.svg`
	fill: #141414;
	height: 25px;
	width: 25px;
`;

const Text = styled.div`
	font-size: 36px;
	margin-left: 15px;
	margin-right: 15px;
    font-family: 'Roboto', sans-serif;
    @media (max-width: 1000px) {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 10px
    };
    @media (max-width: 800px) {
        font-size: 22px;
    };
`;

class SkillsHeader extends Component {
	render() {
		return(
			<Wrapper>
                <Cactus id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.52 82.67"><path d="M60.3,69.3c0,2.43,0,4.81,0,7.19,0,1.64,0,3.29-.17,4.91-.46,4.19-4.42,7.95-8.62,8.5A10.4,10.4,0,0,1,40.63,84a10.63,10.63,0,0,1-.93-4.72V59c-3.8-1-7.55-1.86-11.29-2.82S21,54.3,17.35,53.23a14.19,14.19,0,0,1-5.8-3.29,8.69,8.69,0,0,1-2.8-6.63V29.44c0-2.19,0-4.39,0-6.59A10.28,10.28,0,0,1,19.68,12.53a10.12,10.12,0,0,1,9.62,9.61c.14,4.27.08,8.55.06,12.83,0,.65.17.92.82,1.07,3,.69,5.89,1.44,8.83,2.17l.69.14V37.5q0-9.76,0-19.55a9.88,9.88,0,0,1,6-9.64,10.21,10.21,0,0,1,14.43,7.75,18.09,18.09,0,0,1,.14,2.21q0,14.75,0,29.47v.9a5.5,5.5,0,0,0,.68-.13c3-.74,6-1.49,9-2.21a.75.75,0,0,0,.69-.88c0-3.83,0-7.67,0-11.51a11.25,11.25,0,0,1,3.65-8.58A10.24,10.24,0,0,1,87,24.83a10.56,10.56,0,0,1,4.22,8.58q0,10.13,0,20.25c0,4.12-2.24,6.76-5.66,8.6a36.46,36.46,0,0,1-8.86,2.94C71.48,66.48,66.23,67.81,61,69.11Z" transform="translate(-8.74 -7.33)"/></Cactus>
				<Text>Skills</Text>
                <Cactus id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82.52 82.67"><path d="M60.3,69.3c0,2.43,0,4.81,0,7.19,0,1.64,0,3.29-.17,4.91-.46,4.19-4.42,7.95-8.62,8.5A10.4,10.4,0,0,1,40.63,84a10.63,10.63,0,0,1-.93-4.72V59c-3.8-1-7.55-1.86-11.29-2.82S21,54.3,17.35,53.23a14.19,14.19,0,0,1-5.8-3.29,8.69,8.69,0,0,1-2.8-6.63V29.44c0-2.19,0-4.39,0-6.59A10.28,10.28,0,0,1,19.68,12.53a10.12,10.12,0,0,1,9.62,9.61c.14,4.27.08,8.55.06,12.83,0,.65.17.92.82,1.07,3,.69,5.89,1.44,8.83,2.17l.69.14V37.5q0-9.76,0-19.55a9.88,9.88,0,0,1,6-9.64,10.21,10.21,0,0,1,14.43,7.75,18.09,18.09,0,0,1,.14,2.21q0,14.75,0,29.47v.9a5.5,5.5,0,0,0,.68-.13c3-.74,6-1.49,9-2.21a.75.75,0,0,0,.69-.88c0-3.83,0-7.67,0-11.51a11.25,11.25,0,0,1,3.65-8.58A10.24,10.24,0,0,1,87,24.83a10.56,10.56,0,0,1,4.22,8.58q0,10.13,0,20.25c0,4.12-2.24,6.76-5.66,8.6a36.46,36.46,0,0,1-8.86,2.94C71.48,66.48,66.23,67.81,61,69.11Z" transform="translate(-8.74 -7.33)"/></Cactus>
			</Wrapper>
		);
	}
}

export default(SkillsHeader);