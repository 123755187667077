import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	margin-top: 30px;	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

const Texas = styled.svg`
	fill: #141414;
	height: 25px;
	width: 25px;
`;

const Text = styled.div`
	font-size: 36px;
	margin-left: 15px;
	margin-right: 15px;
	font-family: 'Roboto', sans-serif;
	@media (max-width: 1000px) {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 10px
    };
    @media (max-width: 800px) {
        font-size: 22px;
    };
`;

class ProjectsHeader extends Component {
	render() {
		return(
			<Wrapper>
                <Texas id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.81 33.55"><path d="M44.37,5.81c-.07.07-.13.17-.21.2a2,2,0,0,0-1.07,1A24.13,24.13,0,0,0,41.6,11c-.49,1.41-1,2.83-1.51,4.21a9.22,9.22,0,0,1-2.21,3.23,4.56,4.56,0,0,1-2.07,1.28q-.87.2-1.74.33c-.76.12-1.53.19-2.3.3l-1.69.26c-.4.07-.79.13-1.18.23s-.41,0-.46-.26A.92.92,0,0,0,28,21.89a7.56,7.56,0,0,0,.64.85,1,1,0,0,1,.3,1c-.18,1-.35,2.1-.5,3.16-.05.29-.15.49-.46.49-.14.78-.29,1.54-.43,2.3a1.29,1.29,0,0,0,.07.43,2.23,2.23,0,0,1-.24,1.55,5,5,0,0,0-.64,1.85c-.08,1.05-.22,2.09-.31,3.13a2.73,2.73,0,0,0,0,.57A1.51,1.51,0,0,1,25.24,39a2.74,2.74,0,0,1-2.07-.2,1.28,1.28,0,0,1-.74-1.39,13.37,13.37,0,0,0-.13-2.69c0-.25-.09-.5-.08-.74a5.24,5.24,0,0,0-.58-2.23c-.19-.45-.51-.89-.38-1.38.26-1-.24-1.94-.26-2.92,0-.07-.12-.13-.17-.2s-.3-.29-.33-.45c-.19-1.08-.34-2.16-.53-3.23a1.22,1.22,0,0,1,.42-1.07,4.85,4.85,0,0,0,.49-.63,1,1,0,0,0-.35-1.43l-.2.33-2.18-.43-.3,0c-1.34-.15-2.68-.28-4-.47a4.89,4.89,0,0,1-2.33-1.13,11.9,11.9,0,0,1-3.57-5.93C7.51,11.38,7.08,10,6.61,8.65A6.35,6.35,0,0,0,5.48,6.46,3.79,3.79,0,0,0,4.9,6l-.35-.23a1,1,0,0,1,1-.15A3.87,3.87,0,0,1,7.6,7.83c.79,1.5,1.54,3,2.35,4.52a12.33,12.33,0,0,0,2,2.84,5.87,5.87,0,0,0,2.89,1.67,7.48,7.48,0,0,0,2.4.11c.86-.07,1.71-.24,2.57-.34a5.07,5.07,0,0,1,.65.09,4.17,4.17,0,0,0,.79,0l1.57-.27.87-.11h.2l3.35.24a4.12,4.12,0,0,1,.47.13,2.45,2.45,0,0,0,1,.09,6,6,0,0,1,2.37.16,8.84,8.84,0,0,0,1.55.13,5.41,5.41,0,0,0,4.2-1.53,20.85,20.85,0,0,0,3.48-5.38c.6-1.14,1.19-2.28,1.86-3.37a2.08,2.08,0,0,1,1.52-1.08c.22,0,.44,0,.66,0ZM20.68,23.64l-.07.08.34,1.8a2.52,2.52,0,0,0,1.26-.76.29.29,0,0,0,0-.27c-.31-.22-.63-.42-.95-.61A5.38,5.38,0,0,0,20.68,23.64Zm7.77,0c-.13,0-.21,0-.26,0-.44.25-.87.51-1.3.77-.19.11-.15.19,0,.34a2.75,2.75,0,0,0,1.23.71Z" transform="translate(-4.55 -5.56)"/></Texas>
				<Text>Projects</Text>
                <Texas id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.81 33.55"><path d="M44.37,5.81c-.07.07-.13.17-.21.2a2,2,0,0,0-1.07,1A24.13,24.13,0,0,0,41.6,11c-.49,1.41-1,2.83-1.51,4.21a9.22,9.22,0,0,1-2.21,3.23,4.56,4.56,0,0,1-2.07,1.28q-.87.2-1.74.33c-.76.12-1.53.19-2.3.3l-1.69.26c-.4.07-.79.13-1.18.23s-.41,0-.46-.26A.92.92,0,0,0,28,21.89a7.56,7.56,0,0,0,.64.85,1,1,0,0,1,.3,1c-.18,1-.35,2.1-.5,3.16-.05.29-.15.49-.46.49-.14.78-.29,1.54-.43,2.3a1.29,1.29,0,0,0,.07.43,2.23,2.23,0,0,1-.24,1.55,5,5,0,0,0-.64,1.85c-.08,1.05-.22,2.09-.31,3.13a2.73,2.73,0,0,0,0,.57A1.51,1.51,0,0,1,25.24,39a2.74,2.74,0,0,1-2.07-.2,1.28,1.28,0,0,1-.74-1.39,13.37,13.37,0,0,0-.13-2.69c0-.25-.09-.5-.08-.74a5.24,5.24,0,0,0-.58-2.23c-.19-.45-.51-.89-.38-1.38.26-1-.24-1.94-.26-2.92,0-.07-.12-.13-.17-.2s-.3-.29-.33-.45c-.19-1.08-.34-2.16-.53-3.23a1.22,1.22,0,0,1,.42-1.07,4.85,4.85,0,0,0,.49-.63,1,1,0,0,0-.35-1.43l-.2.33-2.18-.43-.3,0c-1.34-.15-2.68-.28-4-.47a4.89,4.89,0,0,1-2.33-1.13,11.9,11.9,0,0,1-3.57-5.93C7.51,11.38,7.08,10,6.61,8.65A6.35,6.35,0,0,0,5.48,6.46,3.79,3.79,0,0,0,4.9,6l-.35-.23a1,1,0,0,1,1-.15A3.87,3.87,0,0,1,7.6,7.83c.79,1.5,1.54,3,2.35,4.52a12.33,12.33,0,0,0,2,2.84,5.87,5.87,0,0,0,2.89,1.67,7.48,7.48,0,0,0,2.4.11c.86-.07,1.71-.24,2.57-.34a5.07,5.07,0,0,1,.65.09,4.17,4.17,0,0,0,.79,0l1.57-.27.87-.11h.2l3.35.24a4.12,4.12,0,0,1,.47.13,2.45,2.45,0,0,0,1,.09,6,6,0,0,1,2.37.16,8.84,8.84,0,0,0,1.55.13,5.41,5.41,0,0,0,4.2-1.53,20.85,20.85,0,0,0,3.48-5.38c.6-1.14,1.19-2.28,1.86-3.37a2.08,2.08,0,0,1,1.52-1.08c.22,0,.44,0,.66,0ZM20.68,23.64l-.07.08.34,1.8a2.52,2.52,0,0,0,1.26-.76.29.29,0,0,0,0-.27c-.31-.22-.63-.42-.95-.61A5.38,5.38,0,0,0,20.68,23.64Zm7.77,0c-.13,0-.21,0-.26,0-.44.25-.87.51-1.3.77-.19.11-.15.19,0,.34a2.75,2.75,0,0,0,1.23.71Z" transform="translate(-4.55 -5.56)"/></Texas>
			</Wrapper>
		);
	}
}

export default(ProjectsHeader);