import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	margin-top: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
`;

const Texas = styled.svg`
	fill: #141414;
	height: 25px;
	width: 25px;
`;

const Text = styled.div`
	font-size: 36px;
	margin-left: 15px;
	margin-right: 15px;
	font-family: 'Roboto', sans-serif;
	@media (max-width: 1000px) {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 10px
    };
    @media (max-width: 800px) {
        font-size: 22px;
    };
`;

const LinkArea = styled.div`
	width: 100%,
	top: 
`;

class AboutHeader extends Component {
	render() {
		return(
			<Wrapper>
				<Texas id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.28 239.34"><path d="M105.08,88.87V45.37c0-4.81.83-5.63,5.72-5.63h49c4.72,0,5.51.82,5.69,5.47q.66,17.74,1.4,35.47c.15,3.6,1.76,5.06,5.37,5a16.77,16.77,0,0,0,2.24-.18,7.65,7.65,0,0,1,8.71,4.8,7.18,7.18,0,0,0,9.7,3.3c4-2,7.46-1.44,11.15,1.24,4.09,3,9.18,2.39,14,2.41a4.52,4.52,0,0,0,1.71-.28c4.31-1.7,8.09-1,12.36,1s8.84.1,13-1.8a24.85,24.85,0,0,0,4.44-2.75,5,5,0,0,1,5.84-.38,12.45,12.45,0,0,1,4.16,3.11c3,4.15,7.39,5.18,11.91,5.94,5.25.88,5.49.9,5.84,6.18.49,7.31.92,14.64,1.17,22a6.31,6.31,0,0,0,2.82,5.47c2.8,1.94,4.16,4.52,4.8,8a23.86,23.86,0,0,0,4.23,10.16c1.41,1.81,2.64,3.39,2.57,5.51-.1,2.54-.09,5.44-1.3,7.49-2.59,4.43-2.14,8.71-.93,13.25a22.82,22.82,0,0,1,.88,5.37c.09,3.33-1.18,5.1-4.44,6-2.87.83-5.84,1.32-8.78,1.86-4.53.81-8.46,2.26-11.31,6.41-3.87,5.65-9.71,9.3-15.26,13.13-4.59,3.17-9.33,6.12-14,9.15-8.66,5.61-18.2,19-14,32.63,1.81,5.87,3.68,11.73,5.74,17.52,1.24,3.47-.42,6.51-4.08,6.77a6,6,0,0,1-1.74,0c-10.26-2.49-20.55-4.87-30.75-7.58a17.17,17.17,0,0,1-10-7.77c-3.73-5.77-6.68-11.7-6.25-18.83.14-2.17-1-3.76-2.74-5-4.67-3.39-9.21-7-11.62-12.45-1.51-3.42-2.89-6.9-4.21-10.4-2.78-7.38-6.44-14.17-13.19-18.66a35.82,35.82,0,0,0-18.57-5.82,6,6,0,0,0-6.17,3.78c-1.37,3-2.57,6-4,8.88-2.77,5.55-5.54,6.9-11.52,5.35-9-2.35-17.55-5.93-24.39-12.5a9.36,9.36,0,0,1-2.67-5.85c-.27-9.68-4.13-17.43-11.12-24-7.71-7.24-15.11-14.8-22.65-22.23-.41-.41-1-.8-1.14-1.3-.44-1.51-1.4-3.41-.86-4.52s2.66-2,4.1-2C59.45,137.94,73,138,86.62,138H99.87c4.17,0,5.2-1,5.2-5.12Q105.09,110.87,105.08,88.87Z" transform="translate(-41.61 -39.74)"/></Texas>
				<Text>About Me</Text>
				<Texas id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251.28 239.34"><path d="M105.08,88.87V45.37c0-4.81.83-5.63,5.72-5.63h49c4.72,0,5.51.82,5.69,5.47q.66,17.74,1.4,35.47c.15,3.6,1.76,5.06,5.37,5a16.77,16.77,0,0,0,2.24-.18,7.65,7.65,0,0,1,8.71,4.8,7.18,7.18,0,0,0,9.7,3.3c4-2,7.46-1.44,11.15,1.24,4.09,3,9.18,2.39,14,2.41a4.52,4.52,0,0,0,1.71-.28c4.31-1.7,8.09-1,12.36,1s8.84.1,13-1.8a24.85,24.85,0,0,0,4.44-2.75,5,5,0,0,1,5.84-.38,12.45,12.45,0,0,1,4.16,3.11c3,4.15,7.39,5.18,11.91,5.94,5.25.88,5.49.9,5.84,6.18.49,7.31.92,14.64,1.17,22a6.31,6.31,0,0,0,2.82,5.47c2.8,1.94,4.16,4.52,4.8,8a23.86,23.86,0,0,0,4.23,10.16c1.41,1.81,2.64,3.39,2.57,5.51-.1,2.54-.09,5.44-1.3,7.49-2.59,4.43-2.14,8.71-.93,13.25a22.82,22.82,0,0,1,.88,5.37c.09,3.33-1.18,5.1-4.44,6-2.87.83-5.84,1.32-8.78,1.86-4.53.81-8.46,2.26-11.31,6.41-3.87,5.65-9.71,9.3-15.26,13.13-4.59,3.17-9.33,6.12-14,9.15-8.66,5.61-18.2,19-14,32.63,1.81,5.87,3.68,11.73,5.74,17.52,1.24,3.47-.42,6.51-4.08,6.77a6,6,0,0,1-1.74,0c-10.26-2.49-20.55-4.87-30.75-7.58a17.17,17.17,0,0,1-10-7.77c-3.73-5.77-6.68-11.7-6.25-18.83.14-2.17-1-3.76-2.74-5-4.67-3.39-9.21-7-11.62-12.45-1.51-3.42-2.89-6.9-4.21-10.4-2.78-7.38-6.44-14.17-13.19-18.66a35.82,35.82,0,0,0-18.57-5.82,6,6,0,0,0-6.17,3.78c-1.37,3-2.57,6-4,8.88-2.77,5.55-5.54,6.9-11.52,5.35-9-2.35-17.55-5.93-24.39-12.5a9.36,9.36,0,0,1-2.67-5.85c-.27-9.68-4.13-17.43-11.12-24-7.71-7.24-15.11-14.8-22.65-22.23-.41-.41-1-.8-1.14-1.3-.44-1.51-1.4-3.41-.86-4.52s2.66-2,4.1-2C59.45,137.94,73,138,86.62,138H99.87c4.17,0,5.2-1,5.2-5.12Q105.09,110.87,105.08,88.87Z" transform="translate(-41.61 -39.74)"/></Texas>
			</Wrapper>
		);
	}
}

export default(AboutHeader);