import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'


//ASSETS//
import data from '../Assets/projects.json';

//COMPONENTS//
import ProjectsHeader from '../Components/Headers/projectsHeader';
import Project from '../Components/project'; 

const Wrapper = styled.div`
	width: 100%;
    margin-top: 30px;
    display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

const ProjectsWrapper = styled.div`
	width: calc(100% - 60px);
	max-width: 1300px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;
	overflow: hidden;
	flex-wrap: wrap;
	transition: all 1s ease-in-out;
`;


const LinkArea = styled.div`
	width: 100%,
	top: 
`;

class Projects extends Component {
	constructor(props){
		super(props);
		this.state = {
			animate: false
		}
	};

	animateTimeout;

	componentDidMount(){
	};

	componentWillUnmount(){
		clearTimeout(this.animateTimeout)
	};

	componentDidUpdate(prevProps){
		if(this.props.animateProjects && this.props.animateProjects !== prevProps.animateProjects){
			this.handleAnimate();
		}
	};

	handleAnimate(){
		this.animateTimeout = setTimeout(() => {
			this.setState({
				animate: true
			});
		}, 100);
	};
	
	render() {
		return(
		<Wrapper>
			<ProjectsHeader/>
			<LinkArea id="Projects1"/>
			<ProjectsWrapper id="ProjectsContent" style={{transform: this.state.animate ? "translateX(0px)" : "translateX(-1000px)", opacity: this.state.animate ? 1 : 0 }}>
			{
				data.map((data, index) =>
					<Project modal={this.props.modal} handleModal={(modal, text, images) => this.props.handleModal(modal, text, images)} key={data + index} images={data.images} platform={data.platform} text={data.text} previewImage={data.previewImage}/>
				)
			}
			</ProjectsWrapper>
		</Wrapper>
		);
	}
}

export default(Projects);