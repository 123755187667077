import React, { Component } from "react";

//STYLES//
import './App.css';

//LIBRARIES//
import styled from 'styled-components'


//COMPONENTS//
import About from './Views/about';
import Footer from './Views/footer';
import Header from './Views/header';
import Intro from './Views/intro';
import Projects from './Views/projects';
import Skills from './Views/skills'
import Modal from './Components/modal';

const AppStyle = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow-x: hidden;
`;
const LinkArea = styled.div`
	width: 100%,
	top: 
`;

class App extends Component{
	constructor(props){
		super(props);
		this.state = {
			aboutAnimation: false,
			skillsAnimation: false,
			projectsAnimation: false,
			modal: false,
			modalText: null,
			modalImages: null
		}
	};

	componentDidMount(){
		document.addEventListener('scroll', () => this.isScrolledIntoView())
	};

	componentDidUpdate(prevState){
		if(this.state.modal && this.state.modal !== prevState.modal){
			document.body.style.overflowY = "hidden"
		}
		else if(!this.state.modal && this.state.modal !== prevState.modal){
			document.body.style.overflowY = "auto"
		}
	};

	handleModal(modal, text, images){
		this.setState({
		modal: modal,
		modalText: text,
		modalImages: images
		});
	};


	isScrolledIntoView() {
		var about = document.getElementById('About1')
		var aboutRect = about.getBoundingClientRect();
		var elemTop = aboutRect.top;
		var elemBottom = aboutRect.bottom;
		var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
		if(isVisible && !this.state.aboutAnimation){
			this.setState({
				aboutAnimation: true
			});
		};
		var skills = document.getElementById('Skills1')
		var skillsRect = skills.getBoundingClientRect();
		var elemTop2 = skillsRect.top;
		var elemBottom2 = skillsRect.bottom;
		var isVisible2 = (elemTop2 >= 0) && (elemBottom2 <= window.innerHeight);
		if(isVisible2 && !this.state.skillsAnimation){
			this.setState({
				skillsAnimation: true
			});
		}
		var projects = document.getElementById('Projects1')
		var projectsRect = projects.getBoundingClientRect();
		var elemTop3 = projectsRect.top;
		var elemBottom3 = projectsRect.bottom;
		var isVisible3 = (elemTop3 >= 0) && (elemBottom3 <= window.innerHeight);
		if(isVisible3 && !this.state.projectsAnimation){
			this.setState({
				projectsAnimation: true
			});
		}
	}

  render(){
	return (
		<AppStyle className="App">
			<Header modal={this.state.modal}/>
			<Intro/>
			<LinkArea id="Projects"/>
			<Projects animateProjects={this.state.projectsAnimation} handleModal={(modal, text, image) => this.handleModal(modal, text, image)} modal={this.state.modal}/>
			<LinkArea id="About"/>
			<About animateAbout={this.state.aboutAnimation}/>
			<LinkArea id="Skills"/>
			<Skills animateSkills={this.state.skillsAnimation}/>
			<Footer modal={this.state.modal}/>
			{
				this.state.modal &&
				<Modal images={this.state.modalImages} text={this.state.modalText} handleModal={(modal, text, image) => this.handleModal(modal, text, image)}/>
			}
		</AppStyle>
	  );
  }
};

export default App;
