import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
	z-index: 99;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity 0.3s ease-in-out;
`;

const Backdrop = styled.div	`
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0px;
	left: 0px;
	background: rgba(0, 0, 0, 0.9);
`;
const ModalBox = styled.div`
	position: relative;
	max-width: 1100px;
	overflow: auto;
	height: calc(100% - 150px);
	border-radius: 4px;
	flex-direction: column
`;

const ImageWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin 30px; 
	font-family: 'Roboto', sans-serif;
	font-size: 90px;
	color: #f1f1f1;
`;

const Image = styled.img `
	height: 400px;
	transition: height 0.3s ease-in-out, opacity 0.5s ease-in-out;
	@media (max-width: 1000px) {
		height: 300px;
    };
    @media (max-width: 800px) {
		height: 150px;
    };
`;


const LeftButton = styled.div `
	outline: none;
	position: absolute;
	left: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin: 15px;
	padding: 10px;
	height: 60px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: rgba(178, 212, 245, 0.5);
	};
	&:focus {
		background: rgba(178, 212, 245, 0.5);
	};
	@media (max-width: 800px) {
		margin: 5px;
		height: 30px;
    };
`;

const RightButton = styled.div`
	outline: none;
	display: flex;
	position: absolute;
	right: 50px;
	justify-content: center;
	align-items: center;
	align-content: center;
	margin: 15px;
	height: 60px;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		background: rgba(178, 212, 245, 0.5);
	};
	&:focus {
		background: rgba(178, 212, 245, 0.5);
	};
	@media (max-width: 800px) {
		margin: 5px;
		height: 30px;
    };
`;

const Svgs = styled.svg`
	fill: #f1f1f1;
	height: 50px;
	@media (max-width: 800px) {
		margin: 5px;
		height: 30px;
	};
`;

const Text = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(100% - 150px);
	color: #f1f1f1;
	font-family: 'Roboto', sans-serif;
	font-size: 20px;
	margin: 30px;
	margin-left: 75px;
	@media (max-width: 1000px) {
		font-size: 16px
    };
    @media (max-width: 800px) {
		font-size: 14px;
    };
`

const Exit = styled.div`
	outline: none;
	padding: 5px;
	position: fixed;
	top: 30px;
	right: 30px;
	color: #f1f1f1;
	font-size: 28px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: #ce2029;
	};
	&:focus {
		color: #ce2029;
	};
`;

class Modal extends Component {
	constructor(props){
		super(props);
		  this.state = {
			  image: this.props.images[0].image,
			  imageIndex: 0,
			  imageOpacity: 1,
			  opacity: 0
		  }
	  };

	opacityTimeout;
	handleCloseTimeout;
	handleImageChange;
	handleImageDelay;

  componentDidMount(){
	 this.opacityTimeout = setTimeout(() =>{
		this.setState({
			opacity: 1
		})
	  },100)
  };

  componentWillUnmount(){
	clearTimeout(this.opacityTimeout, this.handleCloseTimeout, this.handleImageChange, this.handleImageDelay);
  }
  
  handleClose(){
	  this.setState({
		  opacity: 0
	  })
	  this.handleCloseTimeout = setTimeout(() =>{
		this.props.handleModal(false, null, null)
	  }, 300);
  };

  handleClick(index){
	this.setState({
		imageOpacity: 0
	});
	this.handleImageChange = setTimeout(() => {
		this.setState({
			image: null
		});
		if(index === "right"){
			if(this.props.images[this.state.imageIndex + 1]){
				this.setState({
					imageIndex: this.state.imageIndex + 1,
					image: this.props.images[this.state.imageIndex + 1].image
				})
			}
			else{
				this.setState({
					imageIndex: 0,
					image: this.props.images[0].image
				})
			}
		}
		else{
			if(this.state.imageIndex === 0){
				this.setState({
					imageIndex: this.props.images.length - 1,
					image: this.props.images[this.props.images.length - 1].image
				});
			}
			else{
				this.setState({
					imageIndex: this.state.imageIndex - 1,
					image: this.props.images[this.state.imageIndex - 1].image
				})
			}
		}
	}, 500)
	this.handleImageDelay = setTimeout(() =>{
		this.setState({
			imageOpacity: 1
		})
	}, 750)

  };
  
  render() {
    return(
        <Wrapper  style={{opacity: this.state.opacity}}>
			<Backdrop onClick={() => this.handleClose()}/>
          	<ModalBox>
				<ImageWrap>
					<LeftButton onClick={() => this.handleClick("left")} tabIndex={1} onKeyDown={(e) => e.key === "Enter" && this.handleClick("left")}>
						<Svgs id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.31 174.44"><path d="M933.38,540.09a30.53,30.53,0,0,1,3,2.23Q967.18,573,997.92,603.8c2.85,2.85,5,6,5,10.25-.06,5.28-2.37,9.36-7.11,11.71-4.91,2.43-9.7,1.82-14.09-1.46a23.6,23.6,0,0,1-2.37-2.22L908,550.7c-7.09-7.09-7.12-14.28-.05-21.35q35.85-35.88,71.74-71.74c5.61-5.61,13-6.38,18.41-2a12.75,12.75,0,0,1,2.89,16.84,23.69,23.69,0,0,1-3.35,4q-30.54,30.6-61.13,61.14C935.76,538.33,934.9,538.88,933.38,540.09Z" transform="translate(-902.61 -452.8)"/></Svgs>
					</LeftButton>
					<Image style={{opacity: this.state.imageOpacity}} src={this.state.image}/>
					<RightButton onClick={() => this.handleClick("right")} tabIndex={1} onKeyDown={(e) => e.key === "Enter" && this.handleClick("right")}>
						<Svgs id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.31 174.36"><path d="M988.07,540.7c-1-.81-1.84-1.38-2.55-2.08L924,477.14c-2.59-2.59-4.61-5.48-4.88-9.28a12.83,12.83,0,0,1,6.91-12.58,13.23,13.23,0,0,1,14.7,1.79c.63.55,1.22,1.14,1.81,1.73l71.56,71.56c7,7,7,14.24.1,21.18q-36,36-71.92,71.91c-5.58,5.58-13,6.3-18.42,1.85A12.71,12.71,0,0,1,921,608.66a24.12,24.12,0,0,1,3.48-4.21q30.63-30.69,61.3-61.33C986.45,542.43,987.1,541.72,988.07,540.7Z" transform="translate(-919.07 -453.84)"/></Svgs>
					</RightButton>
				</ImageWrap>
				<Text>
					{this.props.text}
				</Text>
				<Exit onClick={() => this.handleClose()} tabIndex={1} onKeyDown={(e) => e.key === "Enter" && this.handleClose()}>
					&#x2715;
				</Exit>
          	</ModalBox>
        </Wrapper>
    );
  }
}

export default(Modal);