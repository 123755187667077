import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
    margin-top: 50px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const HeaderImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
`;

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00020f;
    opacity: 0.85;
`

const ImageAndTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
`;

const PortfolioImage = styled.img`
    height: 250px;
    width: 250px;
    border: 2px solid #050A30;
    border-radius: 200px;
    transition: all ease-in-out 0.3s;
    @media (max-width: 1000px) {
        height: 175px;
        width: 175px;
    };
    @media (max-width: 800px) {
        height: 125px;
        width: 125px;
    };
`;

const PortfolioHello = styled.div `
    margin: 20px;   
    margin-bottom: 10px;
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    color: #f1f1f1;
    text-align: center;
    transition: all ease-in-out 0.3s;
    @media (max-width: 1000px) {
        font-size: 28px;
        margin-bottom: 5px;
        margin-top: 10px
    };
    @media (max-width: 800px) {
        font-size: 22px;
    };
`;

const PortfolioText = styled.div`
    margin: 20px;
    margin-top: 10px;
    font-size: 20px;    
    font-family: 'Roboto', sans-serif;
    color: #f1f1f1;
    text-align: center;
    transition: all ease-in-out 0.3s;
    @media (max-width: 1000px) {
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 5px
    };
    @media (max-width: 800px) {
        font-size: 14px;
    };
`;


class Introduction extends Component {
  render() {
    return(
        <Wrapper id="Home">
            <Overlay/>
            <ImageAndTextWrapper>
                <PortfolioImage src={"https://web-storage-david.s3-us-west-2.amazonaws.com/portfolio/ProfilePic.jpg"}/>
                <PortfolioHello>Welcome to my portfolio!</PortfolioHello>
                <PortfolioText>My name is David Jansen, and I am a Senior Front-End Developer working remotely out of Austin, Texas!</PortfolioText>
            </ImageAndTextWrapper>
            <HeaderImage src={"https://web-storage-david.s3-us-west-2.amazonaws.com/portfolio/AustinDowntownColor.jpg"}/>
        </Wrapper>
    );
  }
}

export default(Introduction);