import React, { Component } from "react";

//LIBRARIES//
import styled from 'styled-components'

const Wrapper = styled.div`
    outline: none;
    cursor: pointer;
    margin: 30px;
    background: #2e3e4e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    border: 4px solid #2e3e4e;
    border-radius: 4px;
    border-bottom: none;
    color: #f1f1f1;
    font-size: 20px;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #B2D4F5;
        color: #2e3e4e;
        border-color: #B2D4F5;
    };
    &:focus{
        background: #B2D4F5;
        color: #2e3e4e;
        border-color: #B2D4F5;
    }
`;
const ProjectSquare = styled.img`
    display: flex;
    height: 150px;
`;

class Project extends Component {
    componentDidUpdate(){
    }
    
    render() {
        return(
            <Wrapper tabIndex={this.props.modal ? null : 1} onKeyDown={(e) => e.key === "Enter" && this.props.handleModal(true, this.props.text, this.props.images)} onClick={() => this.props.handleModal(true, this.props.text, this.props.images)}>
                <ProjectSquare src={this.props.previewImage}/>
                {this.props.platform}
            </Wrapper>
        );
    }
}

export default(Project);